import { render, staticRenderFns } from "./product_sidebar.vue?vue&type=template&id=5bdecee7"
import script from "./product_sidebar.vue?vue&type=script&lang=js"
export * from "./product_sidebar.vue?vue&type=script&lang=js"
import style0 from "./product_sidebar.vue?vue&type=style&index=0&id=5bdecee7&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home2/staoush/public_will/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5bdecee7')) {
      api.createRecord('5bdecee7', component.options)
    } else {
      api.reload('5bdecee7', component.options)
    }
    module.hot.accept("./product_sidebar.vue?vue&type=template&id=5bdecee7", function () {
      api.rerender('5bdecee7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sites/default/js/components/shop/item/product_sidebar.vue"
export default component.exports